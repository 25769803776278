<template>
  <div>
    <div v-if="isVisible">
      <v-card outlined>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <span>Дата: {{ formatTodayDate(detail.created_at) }}</span><br>
                <span>Агент: {{ formatValue('users.agents', detail.user_id) }}</span><br>
                <span>{{ formatValue('userLog.className', detail.class_name) }} №{{ detail.entity_id }}</span><br>
                <span>Действие: {{ formatValue('userLog.actions', detail.action) }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2"
        v-if="detail.logItems.length"
      >
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="title">Подробности</div>
                  </v-col>
                </v-row>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>
                        Название поля:
                      </th>
                      <th class="text-left">
                        Действие
                      </th>
                      <th class="text-left">
                        Старое значение:
                      </th>
                      <th class="text-left">
                        Новое значение:
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="logItem in logItems"
                      :key="logItem.id"
                    >
                      <td>{{ fullPropertyName(logItem.property_name) }}
                      </td>
                      <td> {{logItem.action === 3 ? 'Удаление' : formatValue('userLog.actions', logItem.action) }}</td>
                      <td>{{ formatLogItemValue(logItem, logItem.old_value) }}</td>
                      <td>{{ formatLogItemValue(logItem, logItem.new_value) }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div
      class="text-center"
      v-else
    >
      <v-progress-circular
        class="text-center"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import userLog from '@/components/mixins/userLog'
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [detail, userLog, formatters],
  name: 'Detail',
  data () {
    return {
      componentCategory: 'log',
      fieldsByType: [
        {
          type: 'timestamp',
          field: [
            'created_at',
            'updated_at',
            'published_at'
          ]
        },
        {
          type: 'boolean',
          field: [
            'is_published',
            'is_verified',
            'is_main',
            'is_agent',
            'state'
          ]
        }
      ],
      isVisible: false
    }
  },
  computed: {
    logItems () {
      return this.detail.logItems.filter(logItem => this.fullPropertyName(logItem.property_name))
    }
  },
  methods: {
    formatLogItemValue (logItem, val) {
      let result = ''
      try {
        val = JSON.parse(val)
      } catch (e) {
      }
      const formattedValue = this.formatValue(logItem.property_name, parseInt(val))
      if (formattedValue) {
        result = formattedValue
      } else {
        const fieldType = this.findValueType(logItem.property_name)
        if (fieldType) {
          if (fieldType === 'boolean') {
            result = this.booleanToHuman(val)
          } else if (fieldType === 'timestamp') {
            result = this.formatTodayDate(val)
          }
        } else {
          if (Array.isArray(val)) {
          } else {
            result = val
          }
        }
      }

      return result
    },
    findValueType (propName) {
      let fieldFound = this.fieldsByType.find(field => field.field.find(item => item === propName.split('.')[1]))
      return fieldFound ? fieldFound.type : null
    },
    booleanToHuman (val) {
      let res = ''
      if (val === '1' || val === 1 || val === true) {
        res = 'Да'
      } else if (val === '0' || val === 0 || val === false) {
        res = 'Нет'
      } else {
        res = val
      }
      return res
    }
  },
  mounted () {
    setTimeout(() => {
      this.isVisible = true
    }, 4000)
  }
}
</script>

<style scoped>

</style>
